import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Link({id, image, imageName, text, link, alt}) {
	return (
		<div className="social-link">
			<div className="container social-link-inner">
				<a href={link}><img alt={alt} className={imageName} src={image} /></a>
				<a href={link}><div id={id} className="link-name">{text}</div></a>
			</div>
		</div>	
	);
}

export default Link;