import React, { useState } from 'react';

function HoverImage({ front, back, alt}) {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <div
      className="hover-image"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img alt={alt} src={isHovered ? back : front} />
    </div>
  );
}

export default HoverImage;