import './App.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap';
import Main from './Main';
import Recipe from './Recipe';
// Lazy load components
const LazyMerch = lazy(() => import('./Merch'));
const LazyGames = lazy(() => import('./Games'));
const LazyConnect = lazy(() => import('./Connect'));

function App() {
  useEffect(() => {
    document.title = 'Tavern Rat Studios';
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/recipe" element={<Recipe />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;