import './App.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './Header';
import About from './About';
import Merch from './Merch';
import Games from './Games';
import Connect from './Connect';

// Lazy load components
const LazyMerch = lazy(() => import('./Merch'));
const LazyGames = lazy(() => import('./Games'));
const LazyConnect = lazy(() => import('./Connect'));

function Main() {
  useEffect(() => {
    document.title = 'Tavern Rat Studios';
  }, []);

  return (
    <div className="Main">
      <Header />
      <Games />
      <About />
      <Merch />
      
      <Connect />
    </div>
  );
}

export default Main;