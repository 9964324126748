import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import pic1 from './FoundingPhoto.jpg';
import pic2 from './TeamPic1.jpg';
import pic3 from './TeamPic2.jpg';
import ImageCarousel from './ImageCarousel';
import ProfileCard from './ProfileCard';
import gage from './Gage.jpg';
import jack from './jack.jpg';
import adam from './adam.jpg';
import kate from './kate.jpg';

function About() {
	return (
		<div className="about" id="about">
			<div className="container">
				<div className="row">
					<div className="col-md-12 section-header">
						ABOUT THE RATS
					</div>
				</div>
			</div>
			<div className="container about-body">
				<div className="row">
					<div className="col-md-6 about-text">
						Tavern Rat Studios is a 4 person indie game studio based out of Seattle, WA (The Space Needle!!!!). We're making a game ABOUT rats, FOR rats, BY rats!
					</div>
					<div className="col-md-6">
						<ImageCarousel />
					</div>
				</div>
			</div>
			<div className="container profiles">
				<div className="row">
					<div className="col-md-3">
						<ProfileCard image={adam} alt={"Photo of Adam sitting in a chair, yawning, with a dog in his lap also yawning."} name={"ADAM"} role={"Engineering"}/>
					</div>
					<div className="col-md-3">
						<ProfileCard image={gage} alt={"Photo of Gage with purple hair, wearing earrings that resemble plastic prize capsules containing dice."} name={"GAGE"} role={"Art & Creative"} />
					</div>
					<div className="col-md-3">
						<ProfileCard image={jack} alt={"Photo of Jack looking directly at the camera, wearing sunglasses."} name={"JACK"} role={"Design"}/>
					</div>
					<div className="col-md-3">
						<ProfileCard image={kate} alt={"Photo of Katelyn wearing a hard hat, sitting with her legs crossed."} name={"KATELYN"} role={"BizOps"}/>
					</div>
				</div>
			</div>
		</div>
	);
}


export default About;