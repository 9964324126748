import React from 'react';

function ProfileCard({image, alt, name, role}) {
	return (
		<div className="profile-card">
			<img alt={alt} src={image} />
			<div className="profile-name">{name}</div>
			<div className="profile-role">Head of {role}</div>
		</div>	
	);
}

export default ProfileCard;