import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './TRSLogo.png';

function Header() {
	return (
		<div className="header">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<img src={logo}></img>
					</div>
					<div className="col-md-2" />
					<div className="col-md-6">
						<div className="links">
							<a href="#games">GAMES</a>
							<a href="#about">ABOUT</a>
							<a href="#merch">MERCH</a>
							
							<a href="#connect">CONNECT</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default Header;