import './App.css';
import React, { Suspense, lazy, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';


function Recipe() {
  useEffect(() => {
    document.title = 'Tavern Rat Studios';
  }, []);

  return (
    <div className="recipe">
<h1 id="gage-s-highly-anticipated-all-in-one-buns">Gage’s Highly Anticipated All-in-One Buns</h1>
<h2 id="equipment">Equipment</h2>
<h3 id="dough">Dough</h3>
<ul>
<li>Food scale (optional)  </li>
<li>Stand mixer or food processor  <ul>
<li>Dough hook for stand mixer  </li>
</ul>
</li>
<li>Rolling pin  </li>
<li>Medium/large bowl  </li>
<li>Plastic wrap  </li>
<li>Bench scraper or sharp knife</li>
</ul>
<h3 id="gravy">Gravy</h3>
<ul>
<li>Pot  </li>
<li>Non-porous spoon/spatula  </li>
<li>Whisk  </li>
<li>Dual metal bowls  <ul>
<li>Only if fast cooling</li>
</ul>
</li>
</ul>
<h3 id="potatoes">Potatoes</h3>
<ul>
<li>Dual metal bowls  <ul>
<li>Only if fast cooling  </li>
</ul>
</li>
<li>Measuring cup</li>
</ul>
<h3 id="frying">Frying</h3>
<ul>
<li>Tongs or steel spider strainer  </li>
<li>BBQ Brush  </li>
<li>Large plate for bread crumbs/rolling  </li>
<li>Mortar and pestle or spice grinder  <ul>
<li>or buy pre-dusted spices  </li>
</ul>
</li>
<li>Deep pot  <ul>
<li>Minimum 6 inches  </li>
<li>Avoid non-stick, heavier is generally better  </li>
<li>If you happen to have a temperature controlled deep fryer that’s even better  </li>
</ul>
</li>
<li>Thermometer  <ul>
<li>Candy or grill thermometer to continuously monitor is better  </li>
<li>Instant read is okay but measure often  </li>
</ul>
</li>
<li>Cookie rack/Grate  <ul>
<li>Optional but helpful  </li>
</ul>
</li>
<li>Paper towel</li>
</ul>
<h2 id="ingredients">Ingredients</h2>
<h3 id="dough">Dough</h3>
<ul>
<li>7 grams active dried yeast  <ul>
<li>1 standard size package  </li>
</ul>
</li>
<li>1 tsp sugar  </li>
<li>¼ water  <ul>
<li>105-110 degrees F  </li>
</ul>
</li>
<li>1 cup milk  <ul>
<li>90-105 degrees  </li>
</ul>
</li>
<li>¼ cup unsalted butter, melted  </li>
<li>¼ cup sugar (50 grams)  </li>
<li>1 egg, beaten  </li>
<li>1 tsp salt  </li>
<li>4 cups flour (480 grams)</li>
</ul>
<h3 id="gravy">Gravy</h3>
<ul>
<li>Vegetable stock  </li>
<li>Flour  </li>
<li>Unsalted butter  </li>
<li>Salt  </li>
<li>Black pepper  </li>
<li>MSG if ya freaky</li>
</ul>
<h3 id="potatoes">Potatoes</h3>
<ul>
<li>Small package of instant mashed potatoes  </li>
<li>Hot water</li>
</ul>
<h3 id="buns">Buns</h3>
<ul>
<li>Dough from before  </li>
<li>1 beaten egg  </li>
<li>Panko bread crumbs  </li>
<li>Thanksgiving Dust  <ul>
<li>2:2:1 Thyme, Rosemary, Sage all ground together</li>
</ul>
</li>
</ul>
<h2 id="process">Process</h2>
<h3 id="prep">Prep</h3>
<ol>
<li>IF NOT PREPARING MASHED POTATOES AND GRAVY THE DAY BEFORE      </li>
<li>Ensure you have ice  </li>
<li>Place 2 separate pints of water into freezer before cooking</li>
</ol>
<h3 id="dough">Dough</h3>
<ol>
<li>Combine sugar, yeast, warm water. Let set for 10-15 minutes until foamy looking while you mix remaining ingredients  </li>
<li>Mix remaining sugar, milk, salt, egg, and butter together  <ol>
<li>Add egg slowly while stirring to avoid any cooking  </li>
</ol>
</li>
<li>Add yeast mix and stir  </li>
<li>Add 3 ½ cups of flour, begin mixing in stand mixer or food processor until combined into cohesive dough  <ol>
<li>Add flour one spoonful at a time if not becoming cohesive and pliable  </li>
</ol>
</li>
<li>Once smooth and with slight bounce back when indented with finger, remove and cover with plastic wrap  </li>
<li>Set in a warm place to proof until doubled in size, about 1 - 1 ½ hours  <ol>
<li>Pre-heating oven to 200 then turning it off is a good way to get a proving drawer  </li>
</ol>
</li>
<li>If not already prepared, make gravy and potatoes while proving</li>
</ol>
<h3 id="gravy">Gravy</h3>
<ol>
<li>Melt 4 Tbsp unsalted butter in a pan over medium heat  </li>
<li>While stirring, add flour until texture resembles oobleck (5-8 Tbsp)  </li>
<li>While stirring, add small portions of vegetable stock to pan  <ol>
<li>Before adding more, allow it to incorporate fully  </li>
<li>There will be a time where this resembles play-doh. Don’t worry about it.  </li>
<li>As you get past the play-doh phase you can start adding liquid a little faster but make sure it stays incorporated  </li>
</ol>
</li>
<li>Allow stock to heat to a simmer  </li>
<li>Add salt, pepper, msg if you’re feelin freaky, and any other seasonings you enjoy in a thanksgiving gravy  </li>
<li>Add cornstarch slurry if not thick enough, allow to thicken  </li>
<li>Take off heat once thickened  </li>
<li>If not prepared ahead of time, place in metal bowl inside another bowl that has 1 pint of freezer water and some ice. Whisk until it stops steaming, leave in cold bath</li>
</ol>
<h3 id="potatoes">Potatoes</h3>
<ol>
<li>Add hot water to potato dust.  </li>
<li>If not prepared ahead of time, place in metal bowl inside another bowl that has 1 pint of freezer water and some ice. Whisk until it stops steaming, leave in cold bath  </li>
<li>Mix with enough gravy to be gloopy, continue to cool</li>
</ol>
<h3 id="buns">Buns</h3>
<ol>
<li>Punch out some air from proven dough  </li>
<li>Place onto a lightly floured surface  </li>
<li>Divide in half, then in half, then in half, then in half trying to stay in equal parts  <ol>
<li>You should end up with 16 dough balls  </li>
<li>If you want to be more precise, weigh the total dough ball and divide by 16, then weigh out each dough ball as you go adding or removing some for consistency  </li>
</ol>
</li>
<li>Press out dough balls one at a time into roughly 5” diameter  </li>
<li>Put one spoonful of mashed potato/gravy mix into center of dough  <ol>
<li>Don’t overdo it!!!! Overdoing it is the single thing that will make your life hardest in this process  </li>
</ol>
</li>
<li>Turn dough over potato mixture, press edges together slightly, and roll them up like a calzone  </li>
<li>Gently, ensuring not to create any leaks, work edges together until all on the bottom of the bun  </li>
<li>Set bun on new seam, covered by plastic wrap or damp cloth  </li>
<li>Allow to prove for 30ish minutes sitting on seam  <ol>
<li>The goal is to get it as sealed as it can be  </li>
</ol>
</li>
<li>While proving, fill pot with 2-3 inches of neutral oil, and with thermometer in the pot heat to 350-365 degrees F  </li>
<li>Brush proved filled buns with beaten egg on all sides  </li>
<li>Roll in panko bread crumbs until covered  </li>
<li>Fry buns in oil, 2-3 at a time  <ol>
<li>Place in oil, then turn over about 15 seconds later to ensure even rise  </li>
<li>Monitor your temperature! You may need to wait a few minutes between batches to let temperature rise back up  <ol>
<li>If temperature is getting too hot, turn down temperature and make 1 or 2 larger batches  </li>
</ol>
</li>
</ol>
</li>
<li>Remove from oil once golden on all sides, place on rack over paper towel to drain  </li>
<li>Put some thanksgiving dust on that thang (to taste) while it cools</li>
</ol>
<p>Finally, enjoy :)</p>

    </div>
  );
}

export default Recipe;