import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import merch from './merch.png';
import teeFront from './tee.avif';
import teeBack from './teeBack.avif';
import shovulFront from './shovulFront.avif';
import shovulBack from './shovulBack.avif';
import moleFront from './moleFront.jpg';
import moleBack from './moleBack.jpg';
import oscarFront from './oscarFront.jpg';
import oscarBack from './oscarBack.jpg';
import HoverImage from './HoverImage';
import TRSLogoSticker from './TRSLogoSticker.webp';
import TRSPurpSticker from './TRSPurple.webp';
import TRSGreenSticker from './TRSGreenSticker.webp';
import TRSPinkSticker from './TRSPink.webp';
import TRSTealSticker from './TRSTeal.webp';
import mischiefSweatshirtSand from './mischiefsweatshirtsand.jpg';
import mischiefSweatShirtGreen from './mischiefsweatshirtgreen.jpg';
import squeakHatBlack from './squeakhatblack.jpg';
import squeakHatBlue from './squeakhatblue.jpg';
import trsHat from './trshat.jpg';
import rrrHat from './ratracerumblehat.jpg';
import mischiefSticker from './mischiefsticker.png';

function Merch() {
	return (
		<div className="merch" id="merch">
			<div className="container">
				<div className="row">
					<div className="col-md-12 section-header">
						MERCH
					</div>
				</div>
				
				<div className="container merch-items">
					<div className="merch-card">
						<a href="https://www.etsy.com/listing/1594371181/tavern-rat-tee?click_key=78f73e557fe2e303275cef71335c41ee8927daf8%3A1594371181&click_sum=8d9767a9&ref=shop_home_recs_1&sts=1">
				        <HoverImage alt="Image of a t-shirt featuring the company's logo on the front and rat concept art on the back. The shirt is available in white with maroon lettering or black with white lettering." front={teeFront} back={teeBack} />
		          		</a>
		          		<div className="merch-name">TRS Tee</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588623628/shovul-scourge-of-the-sandbox-hoodie?click_key=c7df686ba5b77edcf4a2111a8b8c00ad2c8fafbc%3A1588623628&click_sum=2195d0a9&ref=shop_home_recs_2&sts=1">
				        <HoverImage alt="Image of a hoodie available in white or black. The front says 'See you in the sandbox,' and the back features Shovul, Scourge of the Sandbox—a character made from sandbox toys with a bucket head and dump truck feet—along with his name." front={shovulBack} back={shovulFront} />
		          		</a>
		          		<div className="merch-name">Shovul Hoodie</div>
		          	</div>
		          	<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588622980/avogadro-garden-guardian-hoodie?click_key=0c68300ad797fe10b99660d3af8032624f0f46d6%3A1588622980&click_sum=66054270&ref=shop_home_recs_3&sts=1">
				        <HoverImage alt="Image of a hoodie available in black or white. The front reads 'Toil in the soil,' and the back features Avogadro, the Garden Guardian—a mole character—along with his name." front={moleBack} back={moleFront} />
		          		</a>
		          		<div className="merch-name">Avogadro Hoodie</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588622796/oscar-pup-of-the-pit?click_key=e6cf9d0717525b01c73b2cc91fc8419982e7299e%3A1588622796&click_sum=849cfe15&ref=shop_home_recs_4&sts=1">
				        <HoverImage alt="Image of a hoodie available in black or white. The front has the words 'boiled' and 'grilled' with X marks next to them, and 'roasted' with a checkmark. The back features Oscar, Pup of the Pit—a wiener dog-shaped hot dog character with mayo and mustard for eyes and a ketchup tongue—along with his name." front={oscarBack} back={oscarFront} />
		          		</a>
		          		<div className="merch-name">Oscar Hoodie</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1767524669/mischief-rat-pack-heavy-blend-crewneck">
				        <HoverImage alt="Image of a sweatshirt available in forest green or sand beige, featuring the word 'Mischief' on the front along with images of eight rats." front={mischiefSweatshirtSand} back={mischiefSweatShirtGreen} />
		          		</a>
		          		<div className="merch-name">Mischief Sweatshirt</div>
	          		</div>

          		</div>
          		<div className="container merch-items">
          			<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1770246432/squeak-speech-bubble-distressed-cap">
				        <HoverImage alt="Image of hat available in black or blue featuring a speech bubble with the word squeak inside of it." front={squeakHatBlue} back={squeakHatBlack} />
		          		</a>
		          		<div className="merch-name">Squeak Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1770246858/rat-race-rumble-distressed-hat">
				        <HoverImage alt="Image of a beige distressed hat featuring a dump truck racing on a street with a checkered flag and the text 'Rat Race Rumble.'" front={rrrHat} back={rrrHat} />
		          		</a>
		          		<div className="merch-name">Rat Race Rumble Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1784441177/tavern-rat-studios-logo-distressed-cap?click_key=172c53bd6587e68d7d9a591c8d3c0b940271ed9f%3A1784441177&click_sum=8de601c4&ref=shop_home_feat_3">
				        <HoverImage alt="Image of a black distressed hat featuring the Tavern Rat Studios Logo." front={trsHat} back={trsHat} />
		          		</a>
		          		<div className="merch-name">TRS Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1784388167/mischief-logo-sticker">
				        <HoverImage alt="Image of a sticker featuring the Mischief logo." front={mischiefSticker} back={mischiefSticker} />
		          		</a>
		          		<div className="merch-name">Mischief Logo Sticker</div>
	          		</div>
					<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1572691847/tavern-rat-logo-round-sticker?click_key=91d179e42083692eb45584d367def837afa20ded%3A1572691847&click_sum=51cd1f23&ref=shop_home_recs_5&sts=1">
				        <HoverImage alt="Image of a round black sticker featuring the Tavern Rat Studios logo." front={TRSLogoSticker} back={TRSLogoSticker} />
		          		</a>
		          		<div className="merch-name">TRS Logo Sticker</div>
	          		</div>
	          		

          		</div>

          		<div className="merch-items-mobile">
          			<div className="merch-card">
						<a href="https://www.etsy.com/listing/1594371181/tavern-rat-tee?click_key=78f73e557fe2e303275cef71335c41ee8927daf8%3A1594371181&click_sum=8d9767a9&ref=shop_home_recs_1&sts=1">
				        <HoverImage alt="Image of a t-shirt featuring the company's logo on the front and rat concept art on the back. The shirt is available in white with maroon lettering or black with white lettering." front={teeFront} back={teeBack} />
		          		</a>
		          		<div className="merch-name">TRS Tee</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588623628/shovul-scourge-of-the-sandbox-hoodie?click_key=c7df686ba5b77edcf4a2111a8b8c00ad2c8fafbc%3A1588623628&click_sum=2195d0a9&ref=shop_home_recs_2&sts=1">
				        <HoverImage alt="Image of a hoodie available in white or black. The front says 'See you in the sandbox,' and the back features Shovul, Scourge of the Sandbox—a character made from sandbox toys with a bucket head and dump truck feet—along with his name." front={shovulBack} back={shovulFront} />
		          		</a>
		          		<div className="merch-name">Shovul Hoodie</div>
		          	</div>
		          	<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588622980/avogadro-garden-guardian-hoodie?click_key=0c68300ad797fe10b99660d3af8032624f0f46d6%3A1588622980&click_sum=66054270&ref=shop_home_recs_3&sts=1">
				        <HoverImage alt="Image of a hoodie available in black or white. The front reads 'Toil in the soil,' and the back features Avogadro, the Garden Guardian—a mole character—along with his name." front={moleBack} back={moleFront} />
		          		</a>
		          		<div className="merch-name">Avogadro Hoodie</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1588622796/oscar-pup-of-the-pit?click_key=e6cf9d0717525b01c73b2cc91fc8419982e7299e%3A1588622796&click_sum=849cfe15&ref=shop_home_recs_4&sts=1">
				        <HoverImage alt="Image of a hoodie available in black or white. The front has the words 'boiled' and 'grilled' with X marks next to them, and 'roasted' with a checkmark. The back features Oscar, Pup of the Pit—a wiener dog-shaped hot dog character with mayo and mustard for eyes and a ketchup tongue—along with his name." front={oscarBack} back={oscarFront} />
		          		</a>
		          		<div className="merch-name">Oscar Hoodie</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1767524669/mischief-rat-pack-heavy-blend-crewneck">
				        <HoverImage alt="Image of a sweatshirt available in forest green or sand beige, featuring the word 'Mischief' on the front along with images of eight rats." front={mischiefSweatshirtSand} back={mischiefSweatShirtGreen} />
		          		</a>
		          		<div className="merch-name">Mischief Sweatshirt</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1770246432/squeak-speech-bubble-distressed-cap">
				        <HoverImage alt="Image of hat available in black or blue featuring a speech bubble with the word squeak inside of it." front={squeakHatBlue} back={squeakHatBlack} />
		          		</a>
		          		<div className="merch-name">Squeak Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1770246858/rat-race-rumble-distressed-hat">
				        <HoverImage alt="Image of a beige distressed hat featuring a dump truck racing on a street with a checkered flag and the text 'Rat Race Rumble.'" front={rrrHat} back={rrrHat} />
		          		</a>
		          		<div className="merch-name">Rat Race Rumble Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1784441177/tavern-rat-studios-logo-distressed-cap?click_key=172c53bd6587e68d7d9a591c8d3c0b940271ed9f%3A1784441177&click_sum=8de601c4&ref=shop_home_feat_3">
				        <HoverImage alt="Image of a black distressed hat featuring the Tavern Rat Studios Logo." front={trsHat} back={trsHat} />
		          		</a>
		          		<div className="merch-name">TRS Hat</div>
	          		</div>
	          		<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1784388167/mischief-logo-sticker">
				        <HoverImage alt="Image of a sticker featuring the Mischief logo." front={mischiefSticker} back={mischiefSticker} />
		          		</a>
		          		<div className="merch-name">Mischief Logo Sticker</div>
	          		</div>
					<div className="merch-card">
		          		<a href="https://www.etsy.com/listing/1572691847/tavern-rat-logo-round-sticker?click_key=91d179e42083692eb45584d367def837afa20ded%3A1572691847&click_sum=51cd1f23&ref=shop_home_recs_5&sts=1">
				        <HoverImage alt="Image of a round black sticker featuring the Tavern Rat Studios logo." front={TRSLogoSticker} back={TRSLogoSticker} />
		          		</a>
		          		<div className="merch-name">TRS Logo Sticker</div>
	          		</div>
		          </div>
		          <div className="mobile-help">Scroll! ---> </div>
			</div>
		</div>
	);
}


export default Merch;