import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import pic1 from './FoundingPhoto.jpg';
import pic2 from './TeamPic1.jpg';
import pic3 from './TeamPic2Real.jpg';

function ImageCarousel() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    pic1,
    pic2,
    pic3
  ];
  const imageAltTexts =[
    "Photo of our four co-founders enjoying snacks together outdoors at a tavern.",
    "Photo of our four co-founders looking down at the camera with the sky as the background.",
    "Photo of four co-founders sitting on a potato-shaped couch. One person is holding a fork, another is holding a spoon, and a third is holding a pillow shaped like a pat of butter. The image is bordered with a design showing Idahoan Potato Shreds."
  ];

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Change image every 3 seconds (adjust as needed)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div className="image-carousel">
      <img alt={imageAltTexts[currentImageIndex]} src={images[currentImageIndex]}  />
    </div>
  );
}

export default ImageCarousel;